



















































import { Vue, Component } from 'vue-property-decorator';
import { unwrapError } from '@/lib/helpers';
import { QuotationService } from '@/lib/services';
import { QuotationInfoResponse } from '@/lib/services/quotation';

import TnfConfig from '@/config';

@Component({
  components: {},
})
export default class QuotationPage extends Vue {
  status: string = 'loading';
  error: string = '';
  link: string = '';
  info: QuotationInfoResponse | null = null;
  loading: boolean = false;

  async created(): Promise<void> {
    this.link = this.$route.params['link'] || '';

    try {
      this.info = await QuotationService.info(this.link);
      this.status = this.info.state === null ? 'decide' : 'done';
    } catch (e) {
      this.status = 'error';
      this.error = unwrapError(e);
    }
  }

  async resolve(accept: boolean): Promise<void> {
    try {
      this.loading = true;
      if (accept) {
        await QuotationService.accept(this.link);
      } else {
        await QuotationService.reject(this.link);
      }
      this.info!.state = accept;
      this.status = 'done';
    } catch (e) {
      this.status = 'error';
      this.error = unwrapError(e);
    } finally {
      this.loading = false;
    }
  }

  get tacLink(): string {
    return `${TnfConfig.api.base}/external/quotation/${this.link}/tac`;
  }
}
